// component
import { AddBusiness, AddToQueue, ContentCut, Games, ReceiptLong, Storefront } from "@mui/icons-material";
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "Shop owners",
    path: "/dashboard/manageShopOwner",
    icon: <AddBusiness />,
  },
  {
    title: "Shops",
    path: "/dashboard/manageShop",
    icon: <Storefront />,
  },
  // {
  //   title: "Cashiers",
  //   path: "/dashboard/manageCashier",
  //   icon: <AddToQueue />,
  // },
  // {
  //   title: "Sub Agents",
  //   path: "/dashboard/manageSubagent",
  //   icon: <AddToQueue />,
  // },
];

export default navConfig;
