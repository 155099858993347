import { useContext, useEffect, useState } from "react";
import { Button, Container, Grid, MenuItem, Select, useTheme, FormControl, ListItemText, Checkbox, OutlinedInput, InputLabel, CircularProgress, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Label } from "reactstrap";
import { AppWidgetSummary } from "../../sections/@dashboard/app";
import TotalEarning from "../../components/TotalEarning/TotalEarning.jsx"
import { fetchShopStat, getShop, getShopOwners, getShopReport } from "../../data/fetchShopOwner";
import RetailTable from "./RetailTable";
import { CampaignContext } from "../../layouts/dashboard/DashboardLayout";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { DataTable } from "../../components/ui/data-table";
import '../../styles/filter.scss'
import { SearchButton } from "../../components/button/searchButton";
import { ReportFilter } from "./ReportFilter";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const menuItemsStyle = {
  height: 40, // Adjust the height as needed
};

// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: 300, // Adjust the max height as needed
//     },
//   },
// };

export default function RetailReport(params) {
  const theme = useTheme();

  const { data, dispatch } = useContext(CampaignContext);
  const [owner, setOwner] = useState([]);
  const [shopOwner, setShopOwner] = useState([]);
  const [shop, setShop] = useState([]);
  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState('today');

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to start of the day
  // fromDate = today;

  const endOfDay = new Date(today);
  endOfDay.setHours(23, 59, 59, 999); // Set time to end of the day
  // toDate = endOfDay;
  const [filterData, setFilterData] = useState({
    from: today,
    to: endOfDay,
    shop: [],
    shopOwner: []
  })
  console.log(filterData);
  const handleChangeShop = (event) => {
    const {
      target: { value },
    } = event;
    const shoponwer = typeof value === 'string' ? value.split(',') : value;
    setFilterData({ ...filterData, shop: shoponwer }
    );
  };


  console.log('date', filterData);
  const handleDateChange = (selectedDate) => {
    let fromDate = null;
    let toDate = null;
    setDate(selectedDate)

    switch (selectedDate) {
      case 'today': {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = today;

        const endOfDay = new Date(today);
        endOfDay.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = endOfDay;
        break;
      }
      case 'yesterday': {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = yesterday;

        const endOfDay = new Date(yesterday);
        endOfDay.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = endOfDay;
        break;
      }
      case 'this_week': {
        const today = new Date();
        const firstDayOfWeek = new Date(today);
        const daysUntilMonday = today.getDay() === 0 ? 6 : today.getDay() - 1;
        firstDayOfWeek.setDate(today.getDate() - daysUntilMonday); // Set to the first day of the week (Monday)
        firstDayOfWeek.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = firstDayOfWeek;

        const lastDayOfWeek = new Date(today);
        const daysUntilSunday = 6 - daysUntilMonday;
        lastDayOfWeek.setDate(today.getDate() + daysUntilSunday); // Set to the last day of the week (Sunday)
        lastDayOfWeek.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = lastDayOfWeek;
        break;
      }
      case 'last_week': {
        const today = new Date();
        const firstDayOfLastWeek = new Date(today);
        const daysUntilMonday = today.getDay() === 0 ? 6 : today.getDay() - 1;
        firstDayOfLastWeek.setDate(today.getDate() - daysUntilMonday - 7); // Set to the first day of the previous week (Monday)
        firstDayOfLastWeek.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = firstDayOfLastWeek;

        const lastDayOfLastWeek = new Date(today);
        const daysUntilSunday = 6 - daysUntilMonday;
        lastDayOfLastWeek.setDate(today.getDate() + daysUntilSunday - 7); // Set to the last day of the previous week (Sunday)
        lastDayOfLastWeek.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = lastDayOfLastWeek;
        break;
      }
      case 'this_month': {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        firstDayOfMonth.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = firstDayOfMonth;

        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        lastDayOfMonth.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = lastDayOfMonth;
        break;
      }
      case 'last_month': {
        const today = new Date();
        const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        firstDayOfLastMonth.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = firstDayOfLastMonth;

        const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        lastDayOfLastMonth.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = lastDayOfLastMonth;
        break;
      }
      // If 'custom' is selected, don't set fromDate and toDate
      default: {
        fromDate = filterData.from;
        toDate = filterData.to;
        break;
      }
    }

    setFilterData({
      ...filterData,
      from: fromDate,
      to: toDate
    });
  };

  const formatDateForInput = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleFetchShop = () => {
    getShop(shop, setShop, dispatch, setLoader);
  }
  const handleFetchShopOwner = () => {
    getShopOwners(shopOwner, setShopOwner, dispatch, setLoader);
  }
  const handleFetchData = () => {
    getShopReport(owner, setOwner, dispatch, setLoader, filterData);
  }
  useEffect(() => {
    handleDateChange('today')
    handleFetchShop();
    handleFetchShopOwner();
    handleFetchData();
  }, []);

  // Filter the shops based on selected shop owners
  const filteredShops = filterData.shopOwner.length ? shop.filter((shop) => filterData.shopOwner.includes(shop.shopOwnerId)) : shop;


  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Revenue Report
          </Typography>
        </Stack>

        <div style={{ display: 'none' }}>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={4}>
              <AppWidgetSummary
                title="Net Balance"
                total={6}
              // icon={"ant-design:android-filled"}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <AppWidgetSummary
                title="Tickets"
                total={10}
                color="error"
              // icon={"ant-design:bug-filled"}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <AppWidgetSummary
                title="All Bets"
                total={12355}
                color="info"
                icon={"ant-design:apple-filled"}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginTop: '10px' }}>
            <Grid item xs={12} sm={4} md={4}>
              <AppWidgetSummary
                title="Paid Bets"
                total={1723315}
                color="warning"
                icon={"ant-design:windows-filled"}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <AppWidgetSummary
                title="Shops"
                total={33}
                color="warning"
                icon={"ant-design:windows-filled"}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <AppWidgetSummary
                title="Margin"
                total={10.5}
                color="warning"
                icon={"ant-design:windows-filled"}
              />
            </Grid>
          </Grid>
        </div>

        <ReportFilter filterData={filterData} setFilterData={setFilterData} handleFetchData={handleFetchData} loader={loader} shop={shop} shopOwner={shopOwner} />

        <RetailTable owner={owner} loader={loader} cash={false} filterData={filterData} handleFetchData={handleFetchData} />

        {/* <DataTable /> */}
      </Container>
    </>
  );
};
