import { Button, Checkbox, CircularProgress, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material"
import { useEffect, useState } from "react";
import { Label } from "reactstrap"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { SearchButton } from "../../components/button/searchButton";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const menuItemsStyle = {
  height: 40, // Adjust the height as needed
};

export const ReportFilter = ({ filterData, setFilterData, handleFetchData, loader, shop, shopOwner }) => {
  const [date, setDate] = useState('today');

  const handleChangeShop = (event) => {
    const {
      target: { value },
    } = event;
    const shoponwer = typeof value === 'string' ? value.split(',') : value;
    setFilterData({ ...filterData, shop: shoponwer }
    );
  };

  const handleChangeShopOwner = (event) => {
    const {
      target: { value },
    } = event;
    const shoponwer = typeof value === 'string' ? value.split(',') : value;

    // setFilterData({ ...filterData, shopOwner: shoponwer });
    // console.log(filterData.shopOwner);
    // console.log(shoponwer);
    // console.log(filterData.shop);
    // const updatedSelectedShops = filterData.shop.filter((shop) =>
    //   shoponwer.includes(shop)
    // );

    // Set the updated selected shops
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      shop: [],
      shopOwner: shoponwer
    }));
  };

  const handleDateChange = (selectedDate) => {
    let fromDate = null;
    let toDate = null;
    setDate(selectedDate)

    switch (selectedDate) {
      case 'today': {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = today;

        const endOfDay = new Date(today);
        endOfDay.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = endOfDay;
        break;
      }
      case 'yesterday': {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = yesterday;

        const endOfDay = new Date(yesterday);
        endOfDay.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = endOfDay;
        break;
      }
      case 'this_week': {
        const today = new Date();
        const firstDayOfWeek = new Date(today);
        const daysUntilMonday = today.getDay() === 0 ? 6 : today.getDay() - 1;
        firstDayOfWeek.setDate(today.getDate() - daysUntilMonday); // Set to the first day of the week (Monday)
        firstDayOfWeek.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = firstDayOfWeek;

        const lastDayOfWeek = new Date(today);
        const daysUntilSunday = 6 - daysUntilMonday;
        lastDayOfWeek.setDate(today.getDate() + daysUntilSunday); // Set to the last day of the week (Sunday)
        lastDayOfWeek.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = lastDayOfWeek;
        break;
      }
      case 'last_week': {
        const today = new Date();
        const firstDayOfLastWeek = new Date(today);
        const daysUntilMonday = today.getDay() === 0 ? 6 : today.getDay() - 1;
        firstDayOfLastWeek.setDate(today.getDate() - daysUntilMonday - 7); // Set to the first day of the previous week (Monday)
        firstDayOfLastWeek.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = firstDayOfLastWeek;

        const lastDayOfLastWeek = new Date(today);
        const daysUntilSunday = 6 - daysUntilMonday;
        lastDayOfLastWeek.setDate(today.getDate() + daysUntilSunday - 7); // Set to the last day of the previous week (Sunday)
        lastDayOfLastWeek.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = lastDayOfLastWeek;
        break;
      }
      case 'this_month': {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        firstDayOfMonth.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = firstDayOfMonth;

        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        lastDayOfMonth.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = lastDayOfMonth;
        break;
      }
      case 'last_month': {
        const today = new Date();
        const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        firstDayOfLastMonth.setHours(0, 0, 0, 0); // Set time to start of the day
        fromDate = firstDayOfLastMonth;

        const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        lastDayOfLastMonth.setHours(23, 59, 59, 999); // Set time to end of the day
        toDate = lastDayOfLastMonth;
        break;
      }
      // If 'custom' is selected, don't set fromDate and toDate
      default: {
        fromDate = filterData.from;
        toDate = filterData.to;
        break;
      }
    }

    setFilterData({
      ...filterData,
      from: fromDate,
      to: toDate
    });
  };

  const formatDateForInput = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  useEffect(() => {
    handleDateChange('today');
  }, [])

  // Filter the shops based on selected shop owners
  const filteredShops = filterData.shopOwner.length ? shop.filter((shop) => filterData.shopOwner.includes(shop.shopOwnerId)) : shop;

  return (
    <div className="fillterContainer">
      <Grid container spacing={3} className="fillterGrid">
        <Grid item xs={12} sm={4} xl={4}>
          <Label>Date</Label>
          <Select fullWidth label={'date'} value={date} defaultValue={"today"}
            onChange={(e) => handleDateChange(e.target.value)}>
            <MenuItem value={"today"}>Today</MenuItem>
            <MenuItem value={"yesterday"}>Yesterday</MenuItem>
            <MenuItem value={"this_week"}>This Week</MenuItem>
            <MenuItem value={"last_week"}>Last Week</MenuItem>
            <MenuItem value={"this_month"}>This Month</MenuItem>
            <MenuItem value={"last_month"}>Last Month</MenuItem>
            <MenuItem value={"custom"}>Custom</MenuItem>
          </Select>
        </Grid>
        {filterData.from !== null && filterData.to !== null && (
          <>
            <Grid item xs={12} sm={4} xl={4}>
              <Label>From</Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem >
                  <DatePicker
                    value={filterData.from ? dayjs(filterData.from) : ''}
                    onChange={(date) => {
                      setDate('custom')
                      setFilterData({
                        ...filterData,
                        from: new Date(date).setHours(0, 0, 0, 0)
                      })
                    }
                    }
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4} xl={4}>
              <Label>To</Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem >
                  <DatePicker
                    value={filterData.to ? dayjs(filterData.to) : ''}
                    onChange={(date) => {
                      setDate('custom')
                      setFilterData({
                        ...filterData,
                        to: new Date(date).setHours(23, 59, 59, 999)
                      })
                    }
                    }
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid>
          </>
        )}
        {/* <Grid item></Grid> */}
      </Grid>

      <Grid container spacing={3} className="fillterGrid">
        <Grid item xs={12} sm={4} xl={4}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-multiple-checkbox-label">Shop owner</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={filterData.shopOwner}
              onChange={handleChangeShopOwner}
              input={<OutlinedInput label="Shop Owner" />}
              // renderValue={(selected) => selected.join(', ')}
              renderValue={(selected) => selected.map(id => shopOwner.find(owner => owner.id === id)?.name).join(', ')}
              MenuProps={MenuProps}
            >
              {shopOwner.map((name) => (
                <MenuItem key={name.id} sx={menuItemsStyle} value={name.id}>
                  <Checkbox checked={filterData.shopOwner.indexOf(name.id) > -1} />
                  <ListItemText primary={name?.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} xl={4}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-multiple-checkbox-label">Shop</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={filterData.shop}
              onChange={handleChangeShop}
              input={<OutlinedInput label="Shop" />}
              renderValue={(selected) => selected.map(id => filteredShops.find(owner => owner.id === id)?.username).join(', ')}
              MenuProps={MenuProps}
            >
              {filteredShops.map((name) => (
                <MenuItem key={name.id} value={name.id} sx={menuItemsStyle}>
                  <Checkbox checked={filterData.shop.indexOf(name.id) > -1} />
                  <ListItemText primary={name?.username} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} xl={4}>
          {
            loader ? (
              <Button variant="contained" fullWidth style={{ height: '40px', padding: '10px', background: '#7FD858' }}><CircularProgress sx={{ color: 'white' }} /></Button>
            ) : (
              <SearchButton handleclick={handleFetchData} text={'Search'} />
              // <Button variant="contained" sx={{
              //   backgroundColor: "#7FD858", '&:hover': {
              //     backgroundColor: '#5db438', // Change the color for hover state
              //   },
              // }} style={{
              //   height: '40px'
              // }} onClick={handleFetchData} fullWidth>Search</Button>
            )
          }
        </Grid>
        {/* <Grid item></Grid> */}
      </Grid>
    </div>
  )
}