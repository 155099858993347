// EditShopOwner.jsx
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { MenuItem, Select } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import locationlist from "./location.json";

import {
  CustomDialog,
  CustomDialogTitle,
  CustomDialogActions,
} from "../dialogstyle";

const localhost = process.env.REACT_APP_API_URL;
const EditShop = ({ open, setOpen, editItem, handleFetchData }) => {
  const [owner, setOwner] = useState([]);

  const handleFetchShopOwner = () => {
    axios
      .get(`${localhost}/shop-owners`)
      .then((res) => {
        console.log(res);
        setOwner(res.data);
      })
      .catch((err) => {
        console.log(err);
        // dispatch({
        //   type: "setShowSnackBar",
        //   payload: {
        //     show: true,
        //     message:
        //       err?.response?.data?.message || err?.message || "Network Error",
        //   },
        // });
      })
      .finally(() => {
        console.log("end");
      });
  };

  useEffect(() => {
    handleFetchShopOwner();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("effect");
    setFormData(editItem);
  }, []);

  const [formData, setFormData] = useState({});
  console.log("initail", formData.location);
  // console.log(formData);

  const [error, setError] = useState({
    name: false,
    username: false,
  });

  const handleSave = () => {
    // Validate required fields
    const requiredFields = [
      "username",
      "status",
      "shopOwnerId",
      "location",
      "rtp",
      // "oddType",
      "stake",
      "currentLimit",
      "defaultLimit",
    ];
    let isValid = true;
    const newError = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newError[field] = true;
        isValid = false;
      } else {
        newError[field] = false;
      }
    });
    console.log(newError);
    // If there are validation errors, update the state and return
    if (!isValid) {
      setError(newError);
      return;
    }

    console.log("going to axios");
    // Add your logic to handle the form data (e.g., send to an API)
    axios
      .put(`${localhost}/shop/${editItem.id}`, {
        ...(formData.name && { name: formData.name }),
        ...(formData.shopOwnerId && { shopOwnerId: formData.shopOwnerId }),
        ...(formData.status && { status: formData.status }),
        ...(formData.rtp && { rtp: formData.rtp }),
        ...(formData.oddType && { oddType: formData.oddType }),
        ...(formData.location && { location: formData.location }),
        ...(formData.currentLimit && { currentLimit: formData.currentLimit }),
        ...(formData.defaultLimit && { defaultLimit: formData.defaultLimit }),
        ...(formData.cashierLimit && { cashierLimit: formData.cashierLimit }),
        ...(formData.maxStake && { maxStake: formData.maxStake }),
        ...(formData.minStake && { minStake: formData.minStake }),
      })
      .then((res) => {
        console.log(res);
        toast.success("Updated successfully...!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        handleFetchData();
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(formData);
    // handleClose();
  };

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });

    // Clear error when the user types something
    setError({ ...error, [field]: false });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <CustomDialogTitle>Edit Shop</CustomDialogTitle>
      <DialogContent>
        {/* Form Fields */}
        <TextField
          margin="normal"
          label="Username"
          fullWidth
          variant="outlined"
          value={formData.username}
          error={error.username}
          onChange={handleChange("username")}
        />
        <p style={{ margin: "0 5px", fontSize: "12px" }}>Shop Owner</p>
        <Select
          fullWidth
          defaultValue={editItem?.shopOwnerId}
          value={formData.shopOwnerId && formData.shopOwnerId}
          onChange={handleChange("shopOwnerId")}
        >
          <MenuItem value={0}>Choose Owner</MenuItem>
          {owner.map((own) => (
            <MenuItem value={own.id}>{own.name}</MenuItem>
          ))}
        </Select>
        <p style={{ margin: "0 5px", fontSize: "12px" }}>status</p>
        <Select
          fullWidth
          value={formData.status === "active" ? "active" : "inactive"}
          onChange={handleChange("status")}
        >
          <MenuItem value="active">active</MenuItem>
          <MenuItem value="inactive">inactive</MenuItem>
        </Select>

        {/* <p style={{ margin: "0 5px", fontSize: "12px" }}>Odd Type</p>
        <Select
          fullWidth
          value={formData.oddType !== undefined && formData.oddType}
          onChange={handleChange("oddType")}
        >
          <MenuItem value="kiron">kiron</MenuItem>
          <MenuItem value="kiron1">kiron1</MenuItem>
          <MenuItem value="rock1">rock1</MenuItem>
          <MenuItem value="rock2">rock2</MenuItem>
        </Select> */}
        <p
          style={{
            margin: "0 5px",
            fontSize: "12px",
            color: error.location ? "red" : "black",
          }}
        >
          Location
        </p>
        <Select
          fullWidth
          value={formData.location !== undefined && formData.location}
          onChange={handleChange("location")}
          error={error.location}
        >
          {locationlist.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>

        <TextField
          margin="normal"
          label="RTP / Margin"
          type="number"
          fullWidth
          variant="outlined"
          value={formData.rtp}
          error={error.rtp}
          onChange={handleChange("rtp")}
        />
        <TextField
          margin="normal"
          label="Default Limit"
          type="number"
          fullWidth
          variant="outlined"
          value={formData.defaultLimit}
          error={error.defaultLimit}
          onChange={handleChange("defaultLimit")}
        />
        <TextField
          margin="normal"
          label="Stake"
          type="number"
          fullWidth
          variant="outlined"
          value={formData.stake}
          error={error.stake}
          onChange={handleChange("maxStake")}
        />
      </DialogContent>
      <CustomDialogActions>
        <Button
          onClick={handleClose}
          variant="contained"
          sx={{ background: "#5db438", color: "white" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          sx={{ background: "#5db438", color: "white" }}
        >
          Save
        </Button>
      </CustomDialogActions>
    </Dialog>
  );
};

export default EditShop;
