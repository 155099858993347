// ExtendLimit.jsx
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import {
  CustomDialog,
  CustomDialogTitle,
  CustomDialogActions,
} from "./dialogstyle";

const localhost = process.env.REACT_APP_API_URL;

const ExtendLimit = ({ open, setOpen, editItem, handleFetchData }) => {
  const handleClose = () => {
    setOpen(false);
  };
  const [loader, setLoader] = useState(false);

  const [formData, setFormData] = useState({
    limit: editItem?.defaultLimit === "" ? "" : String(editItem?.defaultLimit),
  });

  const [error, setError] = useState({
    limit: false,
  });

  const handleSave = () => {
    // Validate required fields
    const requiredFields = ["limit"];
    let isValid = true;
    const newError = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newError[field] = true;
        isValid = false;
      } else {
        newError[field] = false;
      }
    });

    // If there are validation errors, update the state and return
    if (!isValid) {
      setError(newError);
      return;
    }
    setLoader(true);
    // Add your logic to handle the form data (e.g., send to an API)
    axios
      .post(`${localhost}/shop/extendLimit/${editItem?.id}`, {
        newLimit: formData.limit,
      })
      .then((res) => {
        console.log(res);
        handleFetchData();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });

    // Clear error when the user types something
    setError({ ...error, [field]: false });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <CustomDialogTitle>Extend Limit</CustomDialogTitle>

      <DialogContent>
        {/* Form Fields */}
        <TextField
          margin="normal"
          label="Current Limit"
          fullWidth
          disabled
          variant="outlined"
          value={editItem?.currentLimit}
        />
        <TextField
          margin="normal"
          label="Increase Limit By"
          fullWidth
          variant="outlined"
          initial={editItem?.defaultLimit}
          value={formData.limit}
          error={error.limit}
          onChange={handleChange("limit")}
        />
      </DialogContent>
      <CustomDialogActions>
        {loader ? (
          <CircularProgress fontSize="" sx={{ fontSize: "12px" }} />
        ) : (
          <>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </>
        )}
      </CustomDialogActions>
    </Dialog>
  );
};

export default ExtendLimit;
